import React, { FC } from 'react';
import { Box, Container, Grid, makeStyles, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { PrimaryButton } from 'components/atoms';
import Link from 'next/link';

export interface ContentSliderProps {
  bgImage: string;
  href: string;
  text1: string;
  text2: string;
  text3: string;
  title: string;
  bgGraphic: string;
}

type StyleProps = {
  bgImage: string;
  bgGraphic: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    cursor: 'pointer',
    height: 600,
    backgroundImage: ({ bgImage }: Partial<StyleProps>) => `url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'left center',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      height: 700,
    },
  },
  text: {
    color: theme.palette.common.white,
    textAlign: 'left',
  },
  buttonLink: {
    '& button': {
      marginTop: theme.spacing(8),
    },
  },
  graphBg: {
    width: '100%',
    height: '100%',
    backgroundImage: ({ bgGraphic }: Partial<StyleProps>) => `url(${bgGraphic})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'absolute',
    animation: '$slideDown 5s ease-in-out infinite',
  },
  '@keyframes slideDown': {
    '0%': { opacity: 0, transform: 'scale(1)' },
    '50%': { opacity: 1, transitionDuration: 'all 1.4s', transform: 'scale(1.03)' },
    '100%': { opacity: 0, transform: 'scale(1)' },
  },
}));

const ContentSlider: FC<ContentSliderProps> = ({ bgImage, href, title, text1, text2, text3, bgGraphic }) => {
  const classes = useStyles({ bgImage, bgGraphic });
  const isMediumUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Grid container className={classes.root}>
      <Container fixed>
        <Box
          className={classes.graphBg}
          data-aos="fade-zoom-in"
          data-aos-delay="800"
          data-aos-duration="3000"
          data-aos-once="false"
          data-aos-offset="300"
        />
        <Typography variant={isMediumUp ? 'h1' : 'h2'} className={classes.text} data-aos="fade-right">
          <Box fontWeight={700} mb={5} mt={isMediumUp ? 32 : 13}>
            {title}
          </Box>
        </Typography>
        <Typography variant="h5" className={classes.text} data-aos="fade-right" data-aos-delay="200">
          <Box lineHeight={1.5}>{text1}</Box>
        </Typography>
        <Typography variant="h5" className={classes.text} data-aos="fade-right" data-aos-delay="300">
          <Box lineHeight={1.5}>{text2}</Box>
        </Typography>
        <Typography variant="h5" className={classes.text} data-aos="fade-right" data-aos-delay="400">
          <Box lineHeight={1.5}>{text3}</Box>
        </Typography>
        <Grid container direction="row" justify="flex-start">
          <Grid item>
            <Link href={href}>
              <a className={classes.buttonLink}>
                <PrimaryButton text={'Discover'} />
              </a>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default ContentSlider;
