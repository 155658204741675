import React, { useEffect, useState } from 'react';
import { compose, withProps } from 'recompose';
import { GoogleMap, Marker, withGoogleMap, InfoWindow, withScriptjs } from 'react-google-maps';
import { defaultMapStyle, getGoogleMapKey } from 'config/mapSettings';
import Image from 'blog/components/Image';

const GoogleMapComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${getGoogleMapKey()}&language=en`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `659px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
)((props) => {
  const handleMarkerClick = (target: number) => {
    setSelectedInfoWindow(target);
  };
  const { locations, zoom, centerCoordinates } = props;
  const [selectedInfoWindow, setSelectedInfoWindow] = useState<number | null>(null);

  useEffect(() => {
    setSelectedInfoWindow(null);
  }, [locations]);

  return (
    <GoogleMap
      defaultOptions={{
        styles: defaultMapStyle,
        fullscreenControl: false,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
      }}
      zoom={zoom}
      center={centerCoordinates}
    >
      {locations.map((value, index) => (
        <Marker
          icon={value.icon}
          position={value}
          key={index}
          onMouseOver={() => {
            handleMarkerClick(index);
          }}
          onMouseOut={() => {
            handleMarkerClick(null);
          }}
        >
          {index === selectedInfoWindow && (
            <InfoWindow
              onCloseClick={() => {
                handleMarkerClick(null);
              }}
            >
              <Image src={value.image} alt="mark icon" layout="fill" />
            </InfoWindow>
          )}
        </Marker>
      ))}
    </GoogleMap>
  );
});

export default GoogleMapComponent;
