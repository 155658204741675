import { ENV } from 'config/env';
import {
  constructionMarkIconSvg,
  energyMarkIconSvg,
  engineeringMarkIconSvg,
  technologyMarkIconSvg,
  wirelessMarkIconSvg,
} from 'svgs/projects';
import {
  constructionAlabamaImage,
  constructionArizonaImage,
  constructionArkansasImage,
  constructionCaliforniaImage,
  constructionConnecticutImage,
  constructionFloridaImage,
  constructionIdahoImage,
  constructionIllinoisImage,
  constructionIndianaImage,
  constructionLouisianaImage,
  constructionMichiganImage,
  constructionMississippiImage,
  constructionMontanaImage,
  constructionNevadaImage,
  constructionNewJerseyImage,
  constructionNewMexicoImage,
  constructionNorthCarolinaImage,
  constructionOhioImage,
  constructionOregonImage,
  constructionPennsylvaniaImage,
  constructionPhiladelphiaImage,
  constructionSouthCarolina2Image,
  constructionSouthCarolinaImage,
  constructionTennesseeImage,
  constructionTexasImage,
  constructionUtahImage,
  constructionVermontImage,
  constructionVirginiaImage,
  constructionWashingtonImage,
  constructionWestVirginiaImage,
  constructionWisconsinImage,
  energyCentralFloridaImage,
  energyChicagoImage,
  energyConnecticutImage,
  energyNewJerseyImage,
  energyNewYorkImage,
  energyNorthFloridaImage,
  energyPennsylvaniaImage,
  energySouthFloridaImage,
  engineeringCaliforniaImage,
  engineeringIdahoImage,
  engineeringIndianaImage,
  engineeringMichiganImage,
  engineeringOregonImage,
  engineeringPennsylvaniaImage,
  engineeringWashingtonImage,
  technologyDeploymentChicagoImage,
  technologyDeploymentCincinnatiImage,
  technologyDeploymentKansasImage,
  technologyDeploymentMassachusettsImage,
  technologyDeploymentNewJerseyImage,
  technologyDeploymentNewYorkBuffaloImage,
  technologyDeploymentNewYorkImage,
  technologyDeploymentNewYorkRochesterImage,
  technologyDeploymentPhiladelphiaImage,
  technologyDeploymentPittsburghImage,
  technologyDeploymentStLouisImage,
  wirelessCaliforniaImage,
  wirelessChicagoImage,
  wirelessColoradoImage,
  wirelessFloridaImage,
  wirelessIdahoImage,
  wirelessIllinoisImage,
  wirelessIndianaImage,
  wirelessMichiganImage,
  wirelessNewJerseyImage,
  wirelessNorthCarolinaImage,
  wirelessOhioImage,
  wirelessOregonImage,
  wirelessPittsburghImage,
  wirelessSouthCarolinaImage,
  wirelessUtahImage,
  wirelessVirginiaImage,
  wirelessWashingtonImage,
} from 'images/map';

export interface CurrentLocationCoordinates {
  lat: number;
  lng: number;
}

export enum CategoryType {
  construction,
  wireless,
  energy,
  engineering,
  technology,
}

export interface CurrentLocation extends CurrentLocationCoordinates {
  // address: string;
  // city: string;
  type: keyof typeof CategoryType;
  image?: string;
  icon: string;
}

export const getGoogleMapKey = () => ENV.GOOGLE_MAP_KEY;

export const defaultMapStyle = [
  {
    featureType: 'all',
    elementType: 'labels.text.fill',
    stylers: [
      {
        saturation: 36,
      },
      {
        color: '#000000',
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#000000',
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 17,
      },
      {
        weight: 1.2,
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#8b9198',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#323336',
      },
    ],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#414954',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#2e2f31',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#7a7c80',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#242427',
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#202022',
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#393a3f',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#202022',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#393a3f',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#202022',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#202124',
      },
    ],
  },
];

export const INITIAL_PROJECTS_ADDRESSES: CurrentLocation[] = [
  {
    lat: 43.800024,
    lng: -120.600148,
    image: constructionOregonImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 43.5010666,
    lng: -114.1999428,
    image: constructionIdahoImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 46.8992211,
    lng: -109.5001843,
    image: constructionMontanaImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 43.8005118,
    lng: -84.7008687,
    image: constructionMichiganImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 40.3000036,
    lng: -82.8017408,
    image: constructionOhioImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 41.6097153,
    lng: -72.6420293,
    image: constructionConnecticutImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 40.196965,
    lng: -89.4318515,
    image: constructionIllinoisImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 47.3002086,
    lng: -119.8025843,
    image: constructionWashingtonImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 39.5498916,
    lng: -74.7429593,
    image: constructionNewJerseyImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 27.7506787,
    lng: -81.6025553,
    image: constructionFloridaImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 37.400401,
    lng: -78.4018786,
    image: constructionVirginiaImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 31.4997429,
    lng: -99.1009796,
    image: constructionTexasImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 35.7006814,
    lng: -86.702343,
    image: constructionTennesseeImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 38.5004204,
    lng: -81.0006298,
    image: constructionWestVirginiaImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 36.5009287,
    lng: -119.7016062,
    image: constructionCaliforniaImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 39.934528,
    lng: -75.186705,
    image: constructionPhiladelphiaImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 39.800111,
    lng: -116.9010338,
    image: constructionAlabamaImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 39.1002799,
    lng: -111.7015737,
    image: constructionArizonaImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 34.300636,
    lng: -111.7002894,
    image: constructionArkansasImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 32.8998427,
    lng: -86.701814,
    image: constructionIndianaImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 34.7001542,
    lng: -92.4017905,
    image: constructionMississippiImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 33.49961,
    lng: -89.651429,
    image: constructionNevadaImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 35.7096426,
    lng: -79.5018324,
    image: constructionNorthCarolinaImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 40.8535876,
    lng: -77.572887,
    image: constructionPennsylvaniaImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 40.8506909,
    lng: -77.7008962,
    image: constructionSouthCarolinaImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 34.036082,
    lng: -80.563727,
    image: constructionSouthCarolina2Image,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 34.107144,
    lng: -106.018066,
    image: constructionNewMexicoImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 30.39183,
    lng: -91.729102,
    image: constructionLouisianaImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 44.7,
    lng: -89.5,
    image: constructionWisconsinImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 44.3893362,
    lng: -72.7107571,
    image: constructionUtahImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 40.1992963,
    lng: -86.151629,
    image: constructionVermontImage,
    type: 'construction',
    icon: constructionMarkIconSvg,
  },
  {
    lat: 40.44067,
    lng: -79.995958,
    image: wirelessPittsburghImage,
    type: 'wireless',
    icon: wirelessMarkIconSvg,
  },
  {
    lat: 40.2126689,
    lng: -86.276482,
    image: wirelessIndianaImage,
    type: 'wireless',
    icon: wirelessMarkIconSvg,
  },
  {
    lat: 41.878113,
    lng: -87.629799,
    image: wirelessChicagoImage,
    type: 'wireless',
    icon: wirelessMarkIconSvg,
  },
  {
    lat: 47.3002086,
    lng: -119.8025843,
    image: wirelessWashingtonImage,
    type: 'wireless',
    icon: wirelessMarkIconSvg,
  },
  {
    lat: 43.800024,
    lng: -120.600148,
    image: wirelessOregonImage,
    type: 'wireless',
    icon: wirelessMarkIconSvg,
  },
  {
    lat: 43.5010666,
    lng: -114.1999428,
    image: wirelessIdahoImage,
    type: 'wireless',
    icon: wirelessMarkIconSvg,
  },
  {
    lat: 36.5009287,
    lng: -119.7016062,
    image: wirelessCaliforniaImage,
    type: 'wireless',
    icon: wirelessMarkIconSvg,
  },
  {
    lat: 44.3893362,
    lng: -72.7107571,
    image: wirelessUtahImage,
    type: 'wireless',
    icon: wirelessMarkIconSvg,
  },
  {
    lat: 40.196965,
    lng: -89.4318515,
    image: wirelessIllinoisImage,
    type: 'wireless',
    icon: wirelessMarkIconSvg,
  },
  {
    lat: 40.3000036,
    lng: -82.8017408,
    image: wirelessOhioImage,
    type: 'wireless',
    icon: wirelessMarkIconSvg,
  },
  {
    lat: 39.5498916,
    lng: -74.7429593,
    image: wirelessNewJerseyImage,
    type: 'wireless',
    icon: wirelessMarkIconSvg,
  },
  {
    lat: 27.7506787,
    lng: -81.6025553,
    image: wirelessFloridaImage,
    type: 'wireless',
    icon: wirelessMarkIconSvg,
  },
  {
    lat: 43.8005118,
    lng: -84.7008687,
    image: wirelessMichiganImage,
    type: 'wireless',
    icon: wirelessMarkIconSvg,
  },
  {
    lat: 39.300036,
    lng: -105.499951,
    image: wirelessColoradoImage,
    type: 'wireless',
    icon: wirelessMarkIconSvg,
  },
  {
    lat: 37.400401,
    lng: -78.4018786,
    image: wirelessVirginiaImage,
    type: 'wireless',
    icon: wirelessMarkIconSvg,
  },
  {
    lat: 35.7096426,
    lng: -79.5018324,
    image: wirelessNorthCarolinaImage,
    type: 'wireless',
    icon: wirelessMarkIconSvg,
  },
  {
    lat: 40.8506909,
    lng: -77.7008962,
    image: wirelessSouthCarolinaImage,
    type: 'wireless',
    icon: wirelessMarkIconSvg,
  },
  {
    lat: 40.8535876,
    lng: -77.572887,
    image: energyPennsylvaniaImage,
    type: 'energy',
    icon: energyMarkIconSvg,
  },
  {
    lat: 41.878113,
    lng: -87.629899,
    image: energyChicagoImage,
    type: 'energy',
    icon: energyMarkIconSvg,
  },
  {
    lat: 30.335441,
    lng: -81.655972,
    image: energyNorthFloridaImage,
    type: 'energy',
    icon: energyMarkIconSvg,
  },
  {
    lat: 28.53759,
    lng: -81.377768,
    image: energyCentralFloridaImage,
    type: 'energy',
    icon: energyMarkIconSvg,
  },
  {
    lat: 25.761931,
    lng: -80.184504,
    image: energySouthFloridaImage,
    type: 'energy',
    icon: energyMarkIconSvg,
  },
  {
    lat: 41.6097153,
    lng: -72.6420293,
    image: energyConnecticutImage,
    type: 'energy',
    icon: energyMarkIconSvg,
  },
  {
    lat: 39.5498916,
    lng: -74.7429593,
    image: energyNewJerseyImage,
    type: 'energy',
    icon: energyMarkIconSvg,
  },
  {
    lat: 40.712864,
    lng: -74.004061,
    image: energyNewYorkImage,
    type: 'energy',
    icon: energyMarkIconSvg,
  },
  {
    lat: 36.5009287,
    lng: -119.7016062,
    image: engineeringCaliforniaImage,
    type: 'engineering',
    icon: engineeringMarkIconSvg,
  },
  {
    lat: 43.8005118,
    lng: -84.7008687,
    image: engineeringMichiganImage,
    type: 'engineering',
    icon: engineeringMarkIconSvg,
  },
  {
    lat: 47.3002086,
    lng: -119.8025843,
    image: engineeringWashingtonImage,
    type: 'engineering',
    icon: engineeringMarkIconSvg,
  },
  {
    lat: 40.8535876,
    lng: -77.572887,
    image: engineeringPennsylvaniaImage,
    type: 'engineering',
    icon: engineeringMarkIconSvg,
  },
  {
    lat: 43.800024,
    lng: -120.600148,
    image: engineeringOregonImage,
    type: 'engineering',
    icon: engineeringMarkIconSvg,
  },
  {
    lat: 43.5010666,
    lng: -114.1999428,
    image: engineeringIdahoImage,
    type: 'engineering',
    icon: engineeringMarkIconSvg,
  },
  {
    lat: 40.2126689,
    lng: -86.276482,
    image: engineeringIndianaImage,
    type: 'engineering',
    icon: engineeringMarkIconSvg,
  },
  {
    lat: 38.627003,
    lng: -90.199402,
    image: technologyDeploymentStLouisImage,
    type: 'technology',
    icon: technologyMarkIconSvg,
  },
  {
    lat: 40.480982,
    lng: -79.895359,
    image: technologyDeploymentPittsburghImage,
    type: 'technology',
    icon: technologyMarkIconSvg,
  },
  {
    lat: 39.099728,
    lng: -94.578568,
    image: technologyDeploymentKansasImage,
    type: 'technology',
    icon: technologyMarkIconSvg,
  },
  {
    lat: 39.952583,
    lng: -75.165222,
    image: technologyDeploymentPhiladelphiaImage,
    type: 'technology',
    icon: technologyMarkIconSvg,
  },
  {
    lat: 39.80282,
    lng: -75.05568,
    image: technologyDeploymentNewJerseyImage,
    type: 'technology',
    icon: technologyMarkIconSvg,
  },
  {
    lat: 42.360081,
    lng: -71.058884,
    image: technologyDeploymentMassachusettsImage,
    type: 'technology',
    icon: technologyMarkIconSvg,
  },
  {
    lat: 40.712776,
    lng: -74.005974,
    image: technologyDeploymentNewYorkImage,
    type: 'technology',
    icon: technologyMarkIconSvg,
  },
  {
    lat: 43.156578,
    lng: -77.608849,
    image: technologyDeploymentNewYorkRochesterImage,
    type: 'technology',
    icon: technologyMarkIconSvg,
  },
  {
    lat: 42.886448,
    lng: -78.878372,
    image: technologyDeploymentNewYorkBuffaloImage,
    type: 'technology',
    icon: technologyMarkIconSvg,
  },
  {
    lat: 39.717723,
    lng: -89.466452,
    image: technologyDeploymentChicagoImage,
    type: 'technology',
    icon: technologyMarkIconSvg,
  },
  {
    lat: 39.103119,
    lng: -84.512016,
    image: technologyDeploymentCincinnatiImage,
    type: 'technology',
    icon: technologyMarkIconSvg,
  },
];
