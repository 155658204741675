export const constructionCaliforniaImage = require('images/map/Construction-California.webp');
export const constructionConnecticutImage = require('images/map/Construction-Connecticut.webp');
export const constructionFloridaImage = require('images/map/Construction-Florida.webp');
export const constructionIdahoImage = require('images/map/Construction-Idaho.webp');
export const constructionMichiganImage = require('images/map/Construction-Michigan.webp');
export const constructionMontanaImage = require('images/map/Construction-Montana.webp');
export const constructionNewJerseyImage = require('images/map/Construction-New-Jersey.webp');
export const constructionOhioImage = require('images/map/Construction-Ohio.webp');
export const constructionOregonImage = require('images/map/Construction-Oregon.webp');
export const constructionPhiladelphiaImage = require('images/map/Construction-philadelphia.webp');
export const constructionTennesseeImage = require('images/map/Construction-Tennessee.webp');
export const constructionTexasImage = require('images/map/Construction-Texas.webp');
export const constructionVirginiaImage = require('images/map/Construction-Virginia.webp');
export const constructionWashingtonImage = require('images/map/Construction-Washington.webp');
export const constructionWestVirginiaImage = require('images/map/Construction-West-Virginia.webp');
export const constructionIllinoisImage = require('images/map/Construction-Illinois.webp');
export const constructionAlabamaImage = require('images/map/Construction-Alabama.webp');
export const constructionArizonaImage = require('images/map/Construction-Arizona.webp');
export const constructionArkansasImage = require('images/map/Construction-Arkansas.webp');
export const constructionIndianaImage = require('images/map/Construction-Indiana.webp');
export const constructionMississippiImage = require('images/map/Construction-Mississippi.webp');
export const constructionNevadaImage = require('images/map/Construction-Nevada.webp');
export const constructionNorthCarolinaImage = require('images/map/Construction-North-Carolina.webp');
export const constructionPennsylvaniaImage = require('images/map/Construction-Pennsylvania.webp');
export const constructionSouthCarolinaImage = require('images/map/Construction-South-Carolina.webp');
export const constructionUtahImage = require('images/map/Construction-Utah.webp');
export const constructionVermontImage = require('images/map/Construction-Vermont.webp');
export const constructionSouthCarolina2Image = require('images/map/Construction-SouthCarolina.webp');
export const constructionNewMexicoImage = require('images/map/Construction-NewMexico.webp');
export const constructionLouisianaImage = require('images/map/Construction-Louisiana.webp');
export const constructionWisconsinImage = require('images/map/Construction-Wisconsin.webp');

export const energyChicagoImage = require('images/map/Energy-Chicago.webp');
export const energyPennsylvaniaImage = require('images/map/Energy-Pennsylvania.webp');
export const energyCentralFloridaImage = require('images/map/Energy-Central-Florida.webp');
export const energySouthFloridaImage = require('images/map/Energy-South-Florida.webp');
export const energyNorthFloridaImage = require('images/map/Energy-North-Florida.webp');
export const energyConnecticutImage = require('images/map/Energy-Connecticut.webp');
export const energyNewJerseyImage = require('images/map/Energy-New-Jersey.webp');
export const energyNewYorkImage = require('images/map/Energy-New-York.webp');

export const engineeringCaliforniaImage = require('images/map/Engineering-California.webp');
export const engineeringMichiganImage = require('images/map/Engineering-Michigan.webp');
export const engineeringPennsylvaniaImage = require('images/map/Engineering-Pennsylvania.webp');
export const engineeringWashingtonImage = require('images/map/Engineering-Washington.webp');
export const engineeringOregonImage = require('images/map/Engineering-Oregon.webp');
export const engineeringIdahoImage = require('images/map/Engineering-Idaho.webp');
export const engineeringIndianaImage = require('images/map/Engineering-Indiana.webp');

export const technologyDeploymentChicagoImage = require('images/map/Technology-Deployment-Chicago.webp');
export const technologyDeploymentCincinnatiImage = require('images/map/Technology-Deployment-Cincinnati.webp');
export const technologyDeploymentKansasImage = require('images/map/Technology-Deployment-Kansas.webp');
export const technologyDeploymentMassachusettsImage = require('images/map/Technology-Deployment-Massachusetts.webp');
export const technologyDeploymentNewJerseyImage = require('images/map/Technology-Deployment-New-Jersey.webp');
export const technologyDeploymentNewYorkImage = require('images/map/Technology-Deployment-New-York.webp');
export const technologyDeploymentNewYorkBuffaloImage = require('images/map/Technology-Deployment-New-York-Buffalo.webp');
export const technologyDeploymentNewYorkRochesterImage = require('images/map/Technology-Deployment-New-York-Rochester.webp');
export const technologyDeploymentPhiladelphiaImage = require('images/map/Technology-Deployment-Philadelphia.webp');
export const technologyDeploymentPittsburghImage = require('images/map/Technology-Deployment-Pittsburgh.webp');
export const technologyDeploymentStLouisImage = require('images/map/Technology-Deployment-St-Louis.webp');

export const wirelessChicagoImage = require('images/map/Wireless-Chicago.webp');
export const wirelessIndianaImage = require('images/map/Wireless-Indiana.webp');
export const wirelessWashingtonImage = require('images/map/Wireless-Washington.webp');
export const wirelessPittsburghImage = require('images/map/Wireless-Pittsburgh.webp');
export const wirelessCaliforniaImage = require('images/map/Wireless-California.webp');
export const wirelessColoradoImage = require('images/map/Wireless-Colorado.webp');
export const wirelessFloridaImage = require('images/map/Wireless-Florida.webp');
export const wirelessIdahoImage = require('images/map/Wireless-Idaho.webp');
export const wirelessIllinoisImage = require('images/map/Wireless-Illinois.webp');
export const wirelessMichiganImage = require('images/map/Wireless-Michigan.webp');
export const wirelessNewJerseyImage = require('images/map/Wireless-New-Jersey.webp');
export const wirelessOhioImage = require('images/map/Wireless-Ohio.webp');
export const wirelessOregonImage = require('images/map/Wireless-Oregon.webp');
export const wirelessUtahImage = require('images/map/Wireless-Utah.webp');
export const wirelessSouthCarolinaImage = require('images/map/Wireless-South-Carolina.webp');
export const wirelessNorthCarolinaImage = require('images/map/Wireless-North-Carolina.webp');
export const wirelessVirginiaImage = require('images/map/Wireless-Virginia.webp');
