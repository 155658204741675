import React from 'react';
import { compose, withProps } from 'recompose';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';
import { getGoogleMapKey } from 'config/mapSettings';

const GoogleMapContactComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${getGoogleMapKey()}&language=en`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `740px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
)((props) => {
  const { locations } = props;

  return (
    <GoogleMap
      defaultOptions={{
        fullscreenControl: false,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
      }}
      defaultZoom={12}
      center={locations}
    >
      <Marker
        // icon={value.icon}
        position={locations}
      />
    </GoogleMap>
  );
});

export default GoogleMapContactComponent;
